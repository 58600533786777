import React, { useRef } from 'react'
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FillArrowLeft, FillArrowRight  } from "../../components/Icons"

const SlickCarousel = styled.div`
  overflow:hidden;
`
const SlickSlideCarousel = styled.div`
  .slick-slide {
    position:relative;
    .card{
      &:after{
        content:'';
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,0.5);
        transition: all 0.5s ease;
        opacity:1;
      }
    }
    &.slick-current {
      .card{
        &:after{
          opacity:0;
        }
      }
    }
  }

  .slick-center{
    position:relative;
    z-index:1;
    transition: all 0.5s ease;
    .card{
      transform: scale(1.2);
      box-shadow:0px 20px 30px 0px rgba(57, 57, 57, 50%);
    }
  }
`

const SlickItem = styled.div`
  outline: none;  
`
const Cardproduct = styled.div`
  position:relative;
  transition: all 300ms ease;
  margin: 40px 0 80px 0;
  @media(min-width:992px){
    margin: 60px 0  90px 0;
  }
  @media(min-width:1200px){
    margin: 80px 0 120px 0;
  }
  .gatsby-image-wrapper{
    display:block;
  }
  
`

const SlickArrowTrack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  width:100%;
  @media(min-width:992px){
    width: 50%;
  }
`

const LeftArrow = styled.button`    
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  position:relative;
  border:2px solid #fff;
  background-color:rgba(0,0,0,0.5);
  width: 38px;
  height: 38px;
  @media(min-width:992px){
    width: 48px;
    height: 48px;
    background-color:transparent;
  }
  @media(min-width:1200px){
    width: 68px;
    height: 68px;
  }
  @media(min-width:1600px){
    width: 88px;
    height: 88px;
  }
  svg{
    transform: scale(0.8);
    opacity: 1;
    transition: transform 0.2s, opacity 0.2s;

    fill:#fff;
    width: 12px;
    @media(min-width:992px){
      width: 14px;
    }
    @media(min-width:1200px){
      width: 16px;
    }
    @media(min-width:1600px){
      width: 20px;
    }
  }
  &:hover{
    svg{
      transform: scale(1);
      opacity: 1
    }
  }
`
const RightArrow = styled(LeftArrow)`

`

// function CircleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <button type='button' aria-label="button"
//       className={className}
//       style={{ ...style, display: "flex"}}
//       onClick={onClick}
//     ><FillArrowLeft/></button>
//   );
// }

// function CirclePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <button type='button' aria-label="button"
//       className={className}
//       style={{ ...style, display: "flex"}}
//       onClick={onClick}
//     ><FillArrowRight/></button>
//   );
// }

const TopSellingCarousel = ({ data }) => {
  const settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '160px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '120px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '80px',
          slidesToShow: 1
        }
      }
    ]
  };

  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  }
  const previous = () => {
    sliderRef.current.slickPrev();
  }

  return (
    <SlickCarousel>
      <SlickSlideCarousel>
        <Slider {...settings} ref={sliderRef}>
          {
            data.map((item, i) => (
              <SlickItem key={i}>
                <Cardproduct className='card'>
                  <GatsbyImage image={item.image.gatsbyImageData} alt={item.image.title} />
                </Cardproduct>
              </SlickItem>
            ))
          }
        </Slider>
      </SlickSlideCarousel>
      <SlickArrowTrack>
        <LeftArrow aria-label="previous" onClick={() => previous()}><FillArrowRight /></LeftArrow>
        <RightArrow aria-label="next" onClick={() => next()}><FillArrowLeft /></RightArrow>
      </SlickArrowTrack>
    </SlickCarousel>
  );
}

export default TopSellingCarousel;
