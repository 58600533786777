import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import PrimaryButton from "../Button/PrimaryButton"
import { LineArrowRightWhiteIcon } from "../Icons"
import { Context } from "../context";

const CallQuote = styled.section`
    position:relative;
    background-color:#161420;
    padding:40px 0;
    @media(min-width:768px){
        padding:60px 0;
    }
    @media(min-width:1200px){
        padding:80px 0;
    }
`
const CallExpert = styled.div`
    position:relative;
    max-width:610px;
    width:100%;
    margin-left: auto;
    color:#fff;
    strong{
        display:block;
        font-weight:400;
        font-size: 22px;
        line-height: 32px;
        margin-bottom:10px;
        @media (min-width: 992px) {    
            font-size: 26px;
            line-height: 36px;
        }
        @media (min-width: 1200px) {
            font-size: 34px;
            line-height: 44px;
        }
    }
    a{
        display:block;
        color:#fff;
        margin-bottom:20px;
        &:hover, &:focus{
            color:#d80c0c;
        }
        @media (min-width: 992px) {
            margin-bottom:30px;
        }
        @media (min-width: 1200px) {
            margin-bottom:40px;
        }
        @media (min-width: 1600px){
            font-size: 80px;
            line-height: 90px;
        }
    }
`
const SectionEmbed = styled.div`
       position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &::before {
        display: block;
        content: "";
        padding-top: 75%;
    }
    &::after {
        content: "";
        background-color:rgba(19,20,19,0.8);
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }

    > video{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        object-fit: cover;
    }
`
const CallUs = () => {
    const [popupContext, setPopupContext] = useContext(Context);

    const HandleModalOpen = () => {
      setPopupContext(true)
    }
    useEffect(() => {
        document.getElementById('vid').play();
        if (popupContext===true) {
            document.querySelector("body").classList.add("modal-open")
        } else {
            document.querySelector("body").classList.remove("modal-open")
        }
        }, [popupContext, setPopupContext])
    return (
        <CallQuote className="section-call">	
            <SectionEmbed>
                <video autoPlay loop muted playsInline id="vid">
                    <source src="/video/callcta.mp4" type="video/mp4" />
                </video>
            </SectionEmbed>
            <div className="container">
                <CallExpert>
                    <strong>Call Our Building Experts Today</strong>
                    <a className="h1" href="tel:8067314816">(806) 731-4816</a>
                    <button type="button" aria-label="button" onClick={HandleModalOpen}><PrimaryButton iconspace="icon-space" text="Get a Quote" iconAfter={<LineArrowRightWhiteIcon />}  />
                    </button>
                </CallExpert>
            </div>
        </CallQuote>
    )
}

export default CallUs


