import React from "react"
import styled from "styled-components"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { Section, Container, SectionDescription, SectionImageBkg, SectionTitle, SectionSubTitle } from "../../components/Section"

const SectionVectorImage = styled(SectionImageBkg)`
    background: #161420;
    left:auto;
    right:0;
    border-radius:0;
    border-bottom:2px solid #C20000;
    @media (min-width: 992px) {
        border-radius: 15px 0 0 80px;
        border:none;
        width: calc((100% + 780px) / 2);
        
    }
    @media (min-width: 1200px) {
        width: calc((100% + 1000px) / 2);
        border-radius: 15px 0 0 150px;
    }
    @media (min-width: 1600px) {     
        border-radius: 15px 0 0 250px;
    }
    > .gatsby-image-wrapper{
       
        border-radius: 15px 0 0 80px;
        @media (min-width: 1600px) {     
            border-radius: 15px 0 0 150px;
        }
        @media (min-width: 1600px) {     
            border-radius: 15px 0 0 250px;
        }
    }
`

const SectionWelcomeImage = styled(SectionImageBkg)`
    width:70%;
    height:auto;
    position:inherit;
    display: inline-block;
    margin-bottom: -5px;
    @media (min-width: 992px) {
        width:auto;
        margin-bottom:0;
    }
`
const Grid = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -15px;
  flex-direction: column-reverse;
  @media (min-width: 992px) { 
    margin:0 -30px;
    flex-direction: row;
  }
`
const GridLeft = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content: center;
  @media (min-width: 992px) {
    padding:0 30px;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
`
const GridRight = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media (min-width: 992px) {     
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`

const SectionWelcome = styled(Section)`    
    padding:40px 0 0;
    @media (min-width: 992px) {
        padding:80px 0 60px;
    }
    @media (min-width: 1200px) {
        padding:120px 0 90px;
    }
    @media (min-width: 1600px) {
        padding:150px 0 120px;
    }
    .container{
        position:relative;
    }
`


const WelcomeSection = ({ data }) => {
	return(
        <SectionWelcome bgColor="#fff"  className="section-welcome">
            <SectionVectorImage>
                <StaticImage src="../../images/outline-building.png" alt="outline-building" /></SectionVectorImage>
            <Container className="container" maxWidth="1640px">
                <Grid>
                    <GridLeft><SectionWelcomeImage><GatsbyImage image={data.image.gatsbyImageData} alt={data.title} /></SectionWelcomeImage></GridLeft>
                    <GridRight>
                        <SectionSubTitle color="#fff" mb="0" className="h2"><span className="stroke-white">{data.label}</span></SectionSubTitle>
                        <SectionTitle color="#fff">{data.title}</SectionTitle>
                        <SectionDescription color="#fff" mb="30px">
                           <div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
                        </SectionDescription>
                    </GridRight>
                </Grid>
            </Container>
        </SectionWelcome>
	)
}
export default WelcomeSection
