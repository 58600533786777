import React from "react"
import { Link } from "gatsby"
import {Section, Container, SectionTitle, ExploreMore } from "../../components/Section"
import TopSellingCarousel from "../../components/TopSellingCarousel"
import PrimaryButton from "../../components/Button/PrimaryButton"

const TopSellingSection = ({ data }) => {
	return(
        <Section ept="120px" epb="120px" xpt="80px" xpb="80px" pt="60px" pb="60px" bgColor="#fff"  className="section-selling">
            <Container>
                <SectionTitle textAlign="center">Our Top Selling <span>TX Buildings</span></SectionTitle>
            </Container>
            <TopSellingCarousel data={data} />
            <ExploreMore mt="0"><Link to="/gallery"><PrimaryButton text="See More Building Styles" /></Link></ExploreMore>
        </Section>
	)
}
export default TopSellingSection
