import React, { Component } from 'react'
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image"
import { SlideLeftArrow, SlideRightArrow} from "../../components/Icons"
import QuotePopUpButton from "../../components/QuotePopUpButton"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const HeroBanner = styled.div`
	display: flex;
	flex-wrap: wrap;
	position: relative;
	flex-direction: column-reverse;
    overflow:hidden;
	@media (min-width: 768px){
        flex-direction:row;
	}
    @media (min-width: 1200px){
        height: 100vh;
    }
`
const HeroColumn = styled.div`
    position:relative;
	width: 100%;
    display: flex;
    align-items:center;    
	@media (min-width: 768px){
		flex: 0 0 41.666667%;
        max-width: 41.666667%;
	}
	@media (min-width: 992px){
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
`
const HeroSlideColumn = styled.div`
	position: relative;
	width: 100%;
	@media (min-width: 768px){
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	@media (min-width: 992px){
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
`
const HeroSlickSlider = styled.div`	
	height:100%;
    .slick-slider, .slick-list, .slick-track{
        height:100%;
    }
    .slick-slide{
        height:100%;
        * {
            height:100%;
        }

    }
`
const HeroSlickItem = styled.div`	
	> .gatsby-image-wrapper {
		display:inline-block;
		height:100%;
		width: 100%;
	}
`
const HeroItemNav = styled.div`	
	
    
`

const SlickCarousel = styled.div`
    position:relative;
    width:100%;
    @media (max-width: 991.98px) {
        padding-top:64px;
    }
    .container {
        padding:0;
        max-width:100%;
        height:100%;
        @media (min-width: 1640px){
            max-width: inherit;
            padding-left: calc((100% - 1640px) / 2);
            padding-right: 0px;
        }
    }
`
const SlickTitle = styled.div`
    white-space:nowrap;
    strong, span{
        display:block;
        font-family: 'Montserrat', sans-serif;
        font-weight: 900;
        letter-spacing: -0.5px;  
        
        font-size: 40px;
        line-height: 50px;
        @media (min-width: 768px) {
            font-size: 50px;
            line-height: 60px;
        }
        @media (min-width: 992px) {
            font-size: 60px;
            line-height: 70px;
        }
        @media (min-width: 1200px) {
            font-size: 70px;
            line-height: 80px;
        }
        @media (min-width: 1600px) {
            font-size: 80px;
            line-height: 90px;
        }
        @media (min-width: 1900px) {
            font-size: 100px;
            line-height: 115px;
        }
    }
    strong{
        color:#002765;
        text-transform:uppercase;
        position:relative;
        &:before{
            content:'';
            position:absolute;
            top:0;
            background-color:#C20000;
            width:36px;
            height:2px;
            left:50%;
            transform:translateX(-50%) ;
            @media (min-width: 768px) {
                left:0;
                transform:translateX(0) ;
            }
        }
    }
`
const SlickSubTitle = styled.div`
    white-space:nowrap;
    
    display:block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    letter-spacing: -0.5px;  
    
    font-size: 40px;
    line-height: 50px;
    @media (min-width: 768px) {
        font-size: 50px;
        line-height: 60px;
    }
    @media (min-width: 992px) {
        font-size: 60px;
        line-height: 70px;
    }
    @media (min-width: 1200px) {
        font-size: 70px;
        line-height: 80px;
    }
    @media (min-width: 1600px) {
        font-size: 80px;
        line-height: 90px;
    }
    @media (min-width: 1900px) {
        font-size: 100px;
        line-height: 115px;
    }
    color:#fff;
    text-shadow: 2px 2px 0px #000, -2px -2px 0px #000, -2px 2px 0px #000, 2px -2px 0px #000;
    letter-spacing: 1px;

`
const SlickLead = styled.div`
    display:inline-block;
    position:relative;
    text-transform:uppercase;
    color:#161420;
    font-family: 'Montserrat', sans-serif;
    padding-bottom:10px;
    margin-bottom:30px;
    font-size: 18px;
    line-height: 28px;
    @media (min-width: 768px) {   
        font-size: 20px;
        line-height: 30px;
    }
    @media (min-width: 992px) {    
        font-size: 26px;
        line-height: 36px;
        margin-bottom:40px;
    }
    @media (min-width: 1200px) {
        font-size: 30px;
        line-height: 40px;
        margin-bottom:50px;
        padding-bottom:10px;
    }
    @media (min-width: 1600px) {
        margin-bottom:70px;
        padding-bottom:20px;
    }
    &:after{
        content: '';
        position:absolute;
        left:0;
        bottom:0;
        width:80%;
        height:1px;
        background-color:#161420;
    }

`
const SlickButton = styled.div`
    .btn{
        > .text{
            @media (max-width: 1199.98px) {
                padding: 6px 12px;
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
`
const SlickImage = styled.div`
    position: relative;
    width: 100%;
    @media(min-width:768px){
        height:100%;
    }
    .gatsby-image-wrapper{
        display:inline-block;
    }
`
const ImageCaption = styled.div`	
	position: relative;
    z-index: 2;
    width: 100%;
	margin:0 auto;
	padding: 30px 15px;
    text-align: center;
    @media (min-width: 768px){		
        text-align: left;
        padding: 80px 0 80px 15px;
    }
	@media (min-width: 992px){		
		padding: 120px 0 120px 30px;
	}
	@media (min-width: 1200px){
		padding: 140px 0 70px 30px;
	}
    .slick-list{
        overflow: inherit;
    }
    .slick-slide{
        @media (min-width: 768px){		
            text-align: left;
        }
    }

`
const SlickArrowTrack = styled.div`
    display: none;
    align-items: center;
    position: absolute;
    right:5px;
    bottom:5px;
    overflow:hidden;
    z-index: 1;  
    @media (min-width: 768px) {
        display: flex;
    }
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius:5px;
        background-color: #161420;
        @media (min-width: 768px) {
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            background-color: rgba(255,255,255,0.5);
        }
    }
    svg{
        position:relative;
        width:24px;
        fill:#fff;
        @media (min-width: 768px) {
            width:28px;
            fill:#161420;
        }
        @media (min-width: 992px) {
            width:auto;
        }
    }
`
const LeftArrow = styled.button`    
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  position:relative;
  padding:0;
  width: 44px;
  height: 44px;  
  @media (min-width: 768px) {
    width: 54px;
    height: 54px;  
  }
  @media (min-width: 992px) {
    width: 64px;
    height: 64px;  
  }
  @media (min-width: 1200px) {
    width: 74px;
    height: 74px;  
  }
  @media (min-width: 1600px) {
    width: 84px;
    height: 84px;  
  }
  &:hover{
    svg{
        animation: toLeftFromRight 0.8s forwards;
        
    }
  }
`
const RightArrow = styled(LeftArrow)`
    &:hover{
        svg{
            animation: toRightFromLeft 0.8s forwards;
        }
    }
`



function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button type='button' aria-label="button"
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><SlideRightArrow/></button>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button type='button' aria-label="button"
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><SlideLeftArrow/></button>
  );
}

export default class AsNavFor extends Component {
    constructor(props) {
      	super(props);
      	this.state = {
		  nav1: null,
		  nav2: null
		};
		this.next = this.next.bind(this);
	  	this.previous = this.previous.bind(this);
    }
  
    componentDidMount() {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
    }
	next() {
		this.slider2.slickNext();
	}
	previous() {
		this.slider2.slickPrev();
	}
  render() {

        const { data } = this.props

		const settingnav = {           
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows:false,
            fade: true,
            autoplay: true,
            speed: 300,
		};

		const settingfor = { 
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,       
		  	nextArrow: <CircleNextArrow />,
			prevArrow: <CirclePrevArrow />,
            autoplay: true,
            speed: 1000,
		};
		return(
			<SlickCarousel>
				<div className="container">
					<HeroBanner>
						<HeroColumn>
                            <ImageCaption>
                                <SlickTitle><strong>{data.title}</strong></SlickTitle>
                                <Slider
                                    asNavFor={this.state.nav1}
                                    ref={slider => (this.slider2 = slider)}
                                    {...settingnav}
                                >
                                    {
                                        data.imageData.map((item, i) => (
                                            <HeroItemNav key={i}>
                                                <SlickSubTitle className='slick-subtitle'>{item.title}</SlickSubTitle>
                                                <SlickLead>{item.description}</SlickLead>
                                            </HeroItemNav>
                                        ))
                                    }
                                </Slider>
                                <SlickButton>
                                    <button type="button" aria-label="button">
                                        <QuotePopUpButton btntext={data.buttonTxt} />
                                    </button>
                                </SlickButton>      
                            </ImageCaption>
						</HeroColumn>
						<HeroSlideColumn>
							<HeroSlickSlider className="hero-slider">
								<Slider               
									asNavFor={this.state.nav2}
									ref={slider => (this.slider1 = slider)}
									arrows={false}
									{...settingfor}
								>
                                    {
                                        data.imageData.map((item, i) => (
                                            <HeroSlickItem key={i}>
                                                <SlickImage className='slick-image'>
                                                    <GatsbyImage image={item.gatsbyImageData} alt={item.title} />
                                                </SlickImage>
                                            </HeroSlickItem>
                                        ))
                                    }
								</Slider>
							</HeroSlickSlider>
						</HeroSlideColumn>
					</HeroBanner>
				</div>
                <SlickArrowTrack>
                    <LeftArrow onClick={this.previous}><SlideLeftArrow /></LeftArrow>
                    <RightArrow onClick={this.next}><SlideRightArrow /></RightArrow>
                </SlickArrowTrack>
			</SlickCarousel>
        );
    }
}

