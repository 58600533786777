import React from "react"
import styled from "styled-components"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { Section, Container, SectionTitle, SectionDescription, SectionImageBkg } from "../../components/Section"
import JoinForm from "../../components/JoinForm"

const SectionVectorImage = styled(SectionImageBkg)`
   background: #161420;    
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0 0 15px 0;
  @media (min-width: 992px) {  
    border-radius: 0 80px 15px 0;
  }
  @media (min-width: 1200px) {     
      border-radius: 0 150px 15px 0;
  }
  @media (min-width: 1410px) {     
      width: calc((100% + 1300px) / 2);
  }
  @media (min-width: 1600px) {     
      border-radius: 0 250px 15px 0;
  }
  > .gatsby-image-wrapper{
      border-radius: 0 80px 15px 0;
      @media (min-width: 1200px) {     
          border-radius: 0 150px 15px 0;
      }
      @media (min-width: 1600px) {     
          border-radius: 0 250px 15px 0;
      }
  }
`
const SectionJoinImage = styled(SectionImageBkg)`
    width:auto;
    height:auto;
    top:auto;
    bottom:0;
    
    > .gatsby-image-wrapper{
      width: 50%;
      @media (min-width: 576px) {   
          width: 60%;
        }
        @media (min-width: 1200px) {     
          width: 80%;            
        }
        @media (min-width: 1600px) {     
          width: 100%;            
        }
    }
`
const Grid = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -15px;
  @media (min-width: 992px) { 
    margin:0 -30px;
  }
`
const GridItem = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media (min-width: 992px) {     
    padding:0 30px;
    max-width:50%;
    flex:0 0 50%;
  }
`

const SectionJoin = styled(Section)`    
    margin-bottom:60px;
    padding:80px 0 80px;
    @media (min-width: 576px) {
        padding:80px 0 180px;
    }
    @media (min-width: 992px) {
        padding:80px 0 120px;
    }
    @media (min-width: 1200px) {
        padding:120px 0 150px;
        margin-bottom:120px;
    }
    @media (min-width: 1600px) {
      padding:150px 0 150px;
      margin-bottom:150px;

    }
    .container{
      position:relative;
    }
`
const CardDefault = styled.div`	
    position:relative;      
    .form-subtitle, .form-title{
        display:none;
    }
    &:before{
        @media (min-width: 992px) {
            content:'';
            border:1px solid #161420;
            border-radius:15px;
            position:absolute;
            width:100%;
            height:100%;
            top:20px;
            right:-20px;
        }
    }
`
const CardDefaultBody = styled.div`	
    position:relative;
    background-color:#fff;
    border-radius:15px;
    box-shadow:25px 25px 45px rgba(0,0,0,0.35);
    padding:20px;
    @media(min-width:768px){
        padding:30px;
    }
    @media(min-width:1200px){
        padding:45px;
    }
`
const JoinUsSection = ({ data, location }) => {
	return(
        <SectionJoin bgColor="#fff"  className="section-join" id="joinUs">
            <SectionVectorImage opacityImg="0.3"><StaticImage src="../../images/black-vector.jpg" alt="black-vector" /></SectionVectorImage>
            <SectionJoinImage><GatsbyImage image={data.image.gatsbyImageData} alt={data.title} /></SectionJoinImage>
            <Container className="container">
                <Grid >
                    <GridItem>                        
                        <SectionTitle color="#fff" mb="0">{data.title}</SectionTitle>
                        <SectionDescription color="#fff" mb="30px" hrcolor="#fff">
                          <p>Together Let's Build Strong and Long-Lasting Structures</p>
                          <hr  />
                          <div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
                        </SectionDescription>
                    </GridItem>
                    <GridItem>
                        <CardDefault>
                            <CardDefaultBody>
                                <JoinForm location={location} />
                            </CardDefaultBody>
                        </CardDefault>
                    </GridItem>
                </Grid>                
            </Container>
        </SectionJoin>
	)
}
export default JoinUsSection
