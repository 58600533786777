import React from "react"
import AddressList from "../AddressList"
import {CardDefault, CardTitle, CardBody} from "../AddressListStyle"

const AddressCard = () => {
  return (
    <CardDefault className="card">
      <CardTitle className="card-title">Address</CardTitle>
      <CardBody className="card-body">
        <AddressList />
      </CardBody>
    </CardDefault>
  )
}

export default AddressCard
