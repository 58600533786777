import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSections from "../sections/Home/HeroSections"
import WelcomeSection from "../sections/Home/WelcomeSection"
import TopSellingSection from "../sections/Home/TopSellingSection"
import JoinUsSection from "../sections/Home/JoinUsSection"
import CustomBuildingSection from "../sections/Home/CustomBuildingSection"
import ReviewSection from "../sections/Home/ReviewSection"
import FastestRouteSection from "../sections/Home/FastestRouteSection"
import CallUs from "../components/CallUs"
import MapSection from "../sections/Home/MapSection"


const IndexPage = ({ data, location }) => {
  const pageData = data.contentfulPageHome
  const heroData = {
    title: pageData.heroSectionTitle,
    buttonTxt: pageData.heroSectionButtonText,
    imageData: pageData.sliderImages
  }
  const whySectionData = {
    prefix: pageData.whySectionTitlePrefix,
    title: pageData.whySectionTitle,
    image: pageData.whyImage,
    description: pageData.whyDescription,
    features: pageData.whyFeatures,
    ctaText: pageData.ctaText
  }
  const fastestRouteData = {
    prefix: pageData.gettingSectionTitlePrefix,
    title: pageData.gettingSectionTitle,
    description: pageData.gettingSectionDescription,
    steps: pageData.gettingStep
  }
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <HeroSections data={heroData} />
      <WelcomeSection data={pageData.welcomeSection} />
      <TopSellingSection data={pageData.topSellingMetalBuildings} />
      <JoinUsSection data={pageData.joinSection} location={location} />
      <CustomBuildingSection data={whySectionData} />
      <ReviewSection />
      <FastestRouteSection data={fastestRouteData} />
      <CallUs />
      <MapSection />
    </Layout>
  );
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    contentfulPageHome {
      metaTitle
      metaDescription
      heroSectionTitle
      heroSectionButtonText
      sliderImages {
        gatsbyImageData(quality: 50, placeholder: BLURRED)
        title
        description
      }
      welcomeSection {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(quality: 30, placeholder: BLURRED)
        }
      }
      joinSection {
        title
        subTitle
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(quality: 30, placeholder: BLURRED)
        }
      }
      topSellingMetalBuildings {
        image {
          gatsbyImageData(quality: 30, placeholder: BLURRED)
          title
        }
      }
      whySectionTitlePrefix
      whySectionTitle
      whyImage {
        gatsbyImageData(quality: 50, placeholder: BLURRED)
      }
      whyDescription {
        childMarkdownRemark {
          html
        }
      }
      whyFeatures {
        content
      }
      ctaText
      gettingSectionTitlePrefix
      gettingSectionTitle
      gettingSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      gettingStep {
        gatsbyImageData(quality: 30, placeholder: BLURRED)
        title
      }
    }
  }
`
