import React, { useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Section, Container, SectionTitle, SectionImageBkg } from "../../components/Section"
import StarRating from "../../components/StarRating"
import { Link } from "gatsby"


const CardDefault = styled.div`
    position:relative ;
    background: #f3f6fb;
    background: -moz-linear-gradient(top,  #f3f6fb 0%, #eaeff7 100%);
    background: -webkit-linear-gradient(top,  #f3f6fb 0%,#eaeff7 100%);
    background: linear-gradient(to bottom,  #f3f6fb 0%,#eaeff7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f6fb', endColorstr='#eaeff7',GradientType=0 );
    max-width: 424px;
    width: 100%;
    margin:0 auto;
    border:3px solid #fff;
    box-shadow: 0 65px 65px rgba(190,197,207,0.63);
    border-radius:22px;
    padding:30px 20px;
    text-align:center;
    @media(min-width:992px){
        border-radius:42px;
        padding:60px 30px 30px;
    }
`
const CardTitle = styled.strong`
  display:block;
  font-family: 'Montserrat', sans-serif;
  padding-bottom:10px;
  margin-bottom:10px;
  border-bottom:1px solid #AAAFB9; 
  font-weight:600;
  letter-spacing: 0.5px;
  font-size:18px;
  line-height:30px; 
  text-align:center;
  @media(min-width:1200px){
    font-size:20px;
    line-height:36px; 
  }
  @media(min-width:1600px){
    font-size:26px;
    line-height:38px; 
  }
`
const CardBody = styled.div`
  margin:0;
  text-align:center;
  min-height:105px;
  > p{
    font-size:16px;
    line-height:28px; 
    font-weight:500;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  white-space: pre-wrap;

    @media(min-width:1200px){
        font-size:18px;
        line-height:30px; 
    }
  }

`
const SectionReview = styled(Section)`
    background: #dae0e8;
    background: -moz-linear-gradient(top,  #dae0e8 0%, #f6f8fc 100%);
    background: -webkit-linear-gradient(top,  #dae0e8 0%,#f6f8fc 100%);
    background: linear-gradient(to bottom,  #dae0e8 0%,#f6f8fc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dae0e8', endColorstr='#f6f8fc',GradientType=0 );

    padding:60px 0 40px;
    @media(min-width:768px){
        padding:80px 0 180px;
    }
    @media(min-width:992px){
        padding:80px 0 210px;
    }
    @media(min-width:1200px){
        padding:100px 0 230px;
    }
    @media(min-width:1440px){
        padding:120px 0 250px;
    }
    @media(min-width:1600px){
        padding:140px 0 270px;
    }

`
const VectorImage = styled(SectionImageBkg)`
    top:auto;
    left:0;
    bottom:0;
    width: auto;
    height: auto;

`
const ReviewImage = styled(SectionImageBkg)`
    display:none;
    @media(min-width:1200px){
        display:block;
        top:auto;
        left:auto;
        right:0;
        bottom:0;
        width: auto;
        height: auto;
        overflow:hidden;
        .gatsby-image-wrapper{
            right:-15%;
        }
    }

`
const ReviewContainer = styled.div`

`
const ShowReview = styled.div`    
    position: relative;
    background: #C20000;
    border-radius: 40px;
    margin: 0 auto 30px auto;
    max-width: 328px;
    width: 100%;
    text-align: center;
    box-shadow:inset 2px 8px 0 rgba(0,0,0,0.16);
    padding: 10px 0;
    @media(min-width:992px){
        padding: 20px 0;
    }
    &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(194, 0, 0, 0);
        border-top-color: #C20000;
        border-width: 20px;
        margin-left: -20px;
    }
`
const ListReview = styled.div`    
    display:flex;
    align-items:center;
    justify-content:center;
    width: 100%;
    height: 100%;
    @media(max-width:767.99px){
        margin-bottom:15px;
        padding-bottom:15px;
        overflow-x:auto;
        overflow-y:hidden;
        white-space:nowrap;
        justify-content: flex-start;

    }

`
const ListItemReview = styled.div`    
    position: relative;    
    padding:0 10px;
    @media(min-width:768px){
        position:absolute;
        padding:0;
        &:after, &:before{
            content: '';
            position:absolute;
            width:25px;
            height:25px;
            border-radius:50%;
            background-color:transparent;     
            opacity:0;
            visibility:hidden;
    
        }
        &:nth-child(1){
            top:0;
            left:30px;
        }
        &:nth-child(2){
            top:0;
            right:30px;
        }
        &:nth-child(5){
            top:calc(100% + 42px);
            left:5%;

        }
        &:nth-child(6){
            top:calc(100% + 42px);
            right:40%;
        }
    }
    &:nth-child(3){
        @media(min-width:768px){
            top:50%;
            left:2%;
            &:before, &:after{
                opacity:1;
                visibility:visible;
            }
            &:before{
                top: -100%;
                left: -10%;       
                background:#C20000;   
            }
            &:after{
                bottom: -100%;
                left:calc(-100% + 35px);
                background:#161420;   
            }
        }
        @media(min-width:992px){
            left:10%;
        }

    }
    &:nth-child(4){
        @media(min-width:768px){
            top:50%;
            right:2%;
            &:before, &:after{
                opacity:1;
                visibility:visible;
            }
            &:before{
                top: -100%;
                left: -10%;       
                background:#161420;   
            }
            &:after{
                bottom: -100%;
                left: -100%;
                background:#C20000;   
            }
        }
        @media(min-width:992px){
            right:5%;
        }   
    }

    &.selected{
        .badge{
            border-color:#C20000;
        }
        .rating{
            background-color:#C20000;
        }
    }
    &:hover, &:focus{
        .badge{
            border-color:#C20000;
        }
        .rating{
            background-color:#C20000;
        }
    }
    
`
const ViewReview = styled.div` 
    max-width: 150px;
    width: 100%;
    cursor:pointer;
    position: relative;
    z-index: 1;
`
const Badge = styled.div`  
    position:relative;
    width:50px;
    height:50px;
    border-radius:50%;
    text-align:center;
    border:2px solid #002765;
    font-size:20px;
    line-height:28px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0 auto -5px auto;
    background-color:#fff;
    transition: all .5s ease;

`
const Rating = styled.div`  
    background-color:#002765;
    border-radius:40px;
    text-align:center;
    padding: 0 15px;
    transition: all .5s ease;
    @media(min-width:768px){
        padding: 3px 15px 6px 15px;
    }
    .stars{
        @media(max-width:767.99px){
        white-space: nowrap;
            label{
                height: 10px;
                width: 10px;
                line-height: 20px;
                + label{
                    margin-left:2px;
                }
            }
        }
    }

`
const CardLink = styled.div`  
    position:relative;
    display:inline-block;
    text-align:center;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    a{
        &:after {
            content: "";
            bottom: -2px;
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            background: #d80c0c;
            transition: width 0.3s ease 0s,left 0.3s ease 0s;
            width: 0px;
            transform: translateX(-50%);
        }
        &:hover{
            &:after{
                width:100%;
            }
        }
    }
`

const posts = [
    {id: 1, name: 'Jerald', desc: 'Love my new Garage. The workers got here about 5pm and was finished at 9:pm. They did a wonderful job and were rill friendly.'},
    {id: 2, name: 'Tiana Santos', desc: 'I am so pleased with our side entry carport. Panhandle Metals has a wonderful business ethic and stand by their product.'},
    {id: 3, name: 'Guy', desc: 'Beginning with Nikki in the Direct Sales Dept. to the crew who arrived at the crack of dawn to get the job done...Thank you for the excellent service.'},
    {id: 4, name: 'Mrs. Tina W', desc: 'The service that I received from Panhandle Metals was excellent and very professional. There were a few set backs such as: permit issues (I needed a building permit before they could set up my carport)'},
    {id: 5, name: 'Jo Ann Hobbie', desc: 'We will highly recommend Panhandle Metals carport to all our family and friends. We are super happy with our building.'},
    {id: 6, name: 'Mrs. Sue Sweetman', desc: 'I would like to compliment you on your crew, The four young men that came to install my carport were very polite and Professional, I was really amazed at how fast they got the job done and it looks fabulous. '},
];

const ReviewSection = () => {
    const [title, setTitle] = useState('Amelia Bulter')
    const [content, setContent] = useState('Good communication and follow up in what I was looking for. Great experience. Would highly recommend to others.')

    
    const listItems = ["J", "T", "G", "T", "J", "S"  ].map((item, index) => (        
        <ListItemReview key={index} onClick={() => {
            setTitle(`${posts[index]['name']}`);
            setContent(`${posts[index]['desc']}`);
        }}>
            <ViewReview>
                <Badge className="badge">{item}</Badge>
                <Rating className="rating"><StarRating name="mr1" ragingId1="r1" ragingId2="r2" ragingId3="r3" ragingId4="r4"  ragingId5="r5" /></Rating>
            </ViewReview>
        </ListItemReview>
    ));


	return(
        <SectionReview className="section-review">
            <VectorImage><StaticImage src="../../images/vector-art.png" alt="vector-art" /></VectorImage>
            <ReviewImage><StaticImage src="../../images/review-buildings.png" alt="review-buildings" /></ReviewImage>
            <Container>
              <SectionTitle textAlign="center" mb="40px">Trusted by Many</SectionTitle>
              <ReviewContainer>
                <ListReview>
                    {listItems}
                </ListReview>
                <CardDefault>
                  <ShowReview><StarRating name="mr1" ragingId1="r1" ragingId2="r2" ragingId3="r3" ragingId4="r4" ragingId5="r5" size="lg" /></ShowReview>
                  <CardTitle>{title}</CardTitle>
                  <CardBody><p>{content}</p></CardBody>
                  <CardLink><Link to='/reviews'>View All Reviews</Link></CardLink>
                </CardDefault>
              </ReviewContainer>
            </Container>
        </SectionReview>
	)
}
export default ReviewSection
