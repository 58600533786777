import React from "react"
import styled from "styled-components"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { Section, Container, SectionTitle, SectionDescription, SectionImageBkg } from "../../components/Section"

const SectionVectorImage = styled(SectionImageBkg)`
    background: #fff;
    bottom: 0;
    top:auto;
    
    height: calc(100% - 40px);
    border-radius: 0 40px 0 0;
    @media (min-width: 992px) {     
        border-radius: 0 80px 0 0;
    }
    @media (min-width: 1200px) {     
        border-radius: 0 140px 0 0;
        height: calc(100% - 80px);
    }
    @media (min-width: 1410px) {     
        width: calc((100% + 1410px) / 2);
    }
    @media (min-width: 1600px) {     
        border-radius: 0 200px 0 0;
        height: calc(100% - 120px);
    }
`
const MediaThumbnail = styled.div`
  position:relative;
`
const MediaBody = styled.div`
    position:relative;
    padding:10px 10px 20px;
    border-radius:15px;
    background-color:#fff;
    border: 1px solid #f5f5f5;
    box-shadow:0 0 5px rgba(0,0,0,0.05);
    width: 100%;
    margin: 0 auto; 
    max-width: 595px;
`
const MediaFigure = styled.div`
  .gatsby-image-wrapper{
    img{
      border-radius:15px;
    }
  } 
`
const MediaCaption = styled.div`
    position:relative;
    text-align:center;
    padding:15px;
    margin: -40px 10px 0;
    @media (min-width: 576px) {
        padding:20px 15px;
        margin: -40px 15px 0;
    }
    
    &:before{
        content: "";
        border-radius: 15px;
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid #f5f5f5;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
    }
`
const MediaTitle = styled.div`
    position:relative;
    text-transform: uppercase;
    font-weight:700;
    color:#161420;
    font-size:16px;
    line-height:26px; 
    @media(min-width:576px){
      font-size:18px;
      line-height:28px; 
    }
    @media(min-width:992px){
        font-size:20px;
        line-height:30px; 
    }
    @media(min-width:1600px){
        font-size:26px;
        line-height:40px;
    }
`

const CardTimelineOuter = styled.div`
  position:relative;
  counter-reset: section;    
  padding-bottom:40px;
  .card{
      position: relative;
      &:before {
        content: "";
        position: absolute;
        border: solid rgba(96, 96, 96, 0.20);
        top: 0;
        bottom: 0;
        border-width: 0 1px 0 0;
        border-radius: 0 0 0 0;
        left: 0;
        width: 0;
        @media (min-width: 768px) {
          left: 2%;
          width: 2%;
        }
        @media (min-width: 992px) {
          left: 5%;
          width: 5%;
        }
      }
      + .card{
        &:after {
          content: "";
          position: absolute;
          border-radius:50%;
          background: #fff;
          box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.2);
          border: 3px solid #F48219;
          left: -10px;
          width: 20px;
          height: 20px;
          top: 20px;
          @media (min-width: 768px) {
            width: 30px;
            height: 30px;
            border: 5px solid #F48219;
            top: 30px;
            left: calc(2% + 5px);
          }
          @media (min-width: 992px) {
            left: calc(10% - 15px);
          }
        }
      }

      &:nth-child(1){
        @media (min-width: 1200px) {
            position:sticky;
            top:0;
            z-index:1;
            background-color:#fff;            
            border-radius: 0 170px 0 0;
        }
        .info {
            padding:40px 0 30px 30px;
            @media (min-width: 992px) {
              padding:80px 0 60px 60px;
            }
            @media (min-width: 1200px) {
              padding:130px 0 60px 60px;
            }
          }
        &:before {
          @media (min-width: 768px) {
            left: 0px;
            top: 0;
            bottom: 0;
            border-width: 1px 0 1px 1px;
            border-radius: 30px 0 0 30px;
            border-top: 0;
            border-top-left-radius: 0;
          }
        }
        &:after {
          content: "";
          position: absolute;
          border: solid rgba(96, 96, 96, 0.20);
          top: 0;
          bottom: 0;
          border-width: 0 0 1px 0;
          border-radius: 0 30px 0 0;
          left: 0;
          width: 0;
          @media (min-width: 768px) {
            left: 2%;
            width: 2%;
          }
          @media (min-width: 992px) {
            left: 5%;
            width: 5%;
          }
        }
      }
      + .card{
        .info{
          &:before{
            @media (min-width: 576px) {
              counter-increment: section;
              content: ""counter(section)"";
              color:#161420;
              font-family: 'Montserrat', sans-serif;
              font-size: 40px;
              line-height:40px;
              font-weight:700;
              position:absolute;
              left: 20px;
              top:10px;
            }
            @media (min-width: 768px) {
              font-size: 50px;
              line-height: 50px;
              left: calc(5% + 30px);
              top:12px;
            }
            @media (min-width: 992px) {
              font-size: 60px;
              line-height: 60px;
              top:15px;
              left: calc(10% + 30px);
            }
          }
        }
      }
    &:nth-child(2){
      .icon{
        background-color:#F48219;
      }
    }
    &:nth-child(3){
      &:after {border-color:#E44BA4;}
      .icon{
        background-color:#E44BA4;
      }
    }
    &:nth-child(4){
      &:after {border-color:#6F5DC6;}
      .icon{
        background-color:#6F5DC6;
      }
    }
    &:nth-child(5){
      &:before{height:10%;}
      &:after {border-color:#53C3CA;}
      .icon{
        background-color:#53C3CA;
      }
    }
  }    
  
  .card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
  }    
  
  .card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
  }
`
const CardTimeline = styled.div`
`
const CardTimelineInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: gray;
  border-radius: 10px;
  padding:0 0 60px 50px;
  @media (min-width: 576px) {
    padding:0 0 60px 100px;
  }
  @media (min-width: 768px) {
    padding:0 0 80px 160px;
  }
  @media (min-width: 992px) {
    padding:0 0 100px 260px;
  }
`
const MediaOutlineFigure = styled.div`
    position: absolute;
    bottom: -30px;
    right: 0;
    @media (min-width: 1440px) {     
        right: 70px;
    }
`


const FastestRouteSection = ({ data }) => {
	return(
        <Section ept="120px" epb="0" xpt="80px" xpb="0" pt="40px" pb="0" bgColor="#161420"  className="section-fastest">
            <SectionVectorImage></SectionVectorImage>
            <Container className="container">
            <CardTimelineOuter>
                <CardTimeline className="card">
                    <CardTimelineInfo className="info">
                        <SectionTitle mb="0"><span className="stroke-black">{data.prefix}</span> {data.title} </SectionTitle>
                        <SectionDescription mb="0">
                          <div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
                        </SectionDescription>
                    </CardTimelineInfo>
                </CardTimeline>

                {
                  data.steps.map((item, i) => (
                    <CardTimeline className="card">
                      <CardTimelineInfo className="info">
                          <MediaThumbnail className="media">                            
                              <MediaOutlineFigure><StaticImage src="../../images/building-outline.png" alt="building-outline" /></MediaOutlineFigure>
                              <MediaBody>
                                  <MediaFigure><GatsbyImage image={item.gatsbyImageData} alt={item.title} /></MediaFigure>
                                  <MediaCaption><MediaTitle>{item.title}</MediaTitle></MediaCaption>
                              </MediaBody>
                          </MediaThumbnail>
                      </CardTimelineInfo>
                    </CardTimeline>
                  ))
                }
            </CardTimelineOuter>
            </Container>
        </Section>
	)
}
export default FastestRouteSection
