import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Section, Container, SectionTitle, SectionImageBkg } from "../../components/Section"
import AddressCard from "../../components/AddressCard"

const SectionLocation = styled.div`
  position:relative;
  padding:20px 10px; 
  @media(min-width:567px){
    padding:40px; 
  }
  @media(min-width:992px){
    padding:60px; 
  }
  @media(min-width:1200px){
    padding:80px; 
  }
  @media(min-width:1600px){
    padding:90px 80px; 
  }

`
const MapImage = styled(SectionImageBkg)`
  overflow:hidden;
  border-radius:15px;
`

const SectionMapBkg = styled(SectionImageBkg)`
  height:auto;
  bottom:0;
  top:auto;
`

const MapSection = () => {
	return(
        <Section ept="120px" epb="150px" xpt="80px" xpb="120px" pt="40px" pb="80px" bgColor="#fff"  className="section-map">
            <SectionMapBkg opacity="0.2"><StaticImage src="../../images/serving-panhandle.png" alt="serving-panhandle" /></SectionMapBkg>
            <Container>
              <SectionTitle className="mb-30" ml="0"><span>Serving</span> in Texas, Oklahoma, and Kansas</SectionTitle>
              <SectionLocation>
                <MapImage><StaticImage src="../../images/map-address.png" alt="map-address" /></MapImage>
                <AddressCard />
              </SectionLocation>
            </Container>
        </Section>
	)
}
export default MapSection
